import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderServiceProps {
    open: boolean
}

function LoadingService({ open }: LoaderServiceProps) {
  return (
    <>
    {open ? <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div> : null
    }
    </>
  );
}

export default LoadingService;