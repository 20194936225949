export function createEmailTemplateString(
  to: string,
  cc: string,
  subject: string,
  body: string
) {
  let str = `<appSpecial~> ${to} </appSpecial~>\n`;
  /*
  if (cc) {
    str += `<appSpecial~> ${cc} </appSpecial~>\n`;
  }
  */
  str += `<appSpecial~> ${cc} </appSpecial~>\n`;
  str += `<appSpecial~> ${subject} </appSpecial~>\n<appSpecial~> ${body} </appSpecial~>`;
  return str;
}

export function extractEmailFieldsFromString(str: string) {
  const regex = /<appSpecial~>\s*(.*?)\s*<\/appSpecial~>/gs;
  const matches = Array.from(str.matchAll(regex));
  const values = matches.map((match) => match[1]);
  return {
    to: values[0] || "",
    cc: values[1] || "",
    subject: values[2] || "",
    body: values[3] || "",
  };
}
