export const initialReminderState = {
    is_done: false,
    action: "",
    due_date: new Date(),
    notes: "",
    client: '',
    assigned_to: ''
};

export const ReminderAction = {
    UPDATE_STATE: 'UPDATE_STATE',
    INPUT_FIELD: 'HANDLE INPUT TEXT',
    SUBMIT_SUCCESS: 'SUBMIT SUCCESS'
}

export const ReminderReducer = (state: any, action: any) => {
    switch (action.type) {
        case ReminderAction.INPUT_FIELD:
            return {
                ...state,
                [action.field]: action.payload
            }
        case ReminderAction.UPDATE_STATE:
            return { ...action.payload };
        case ReminderAction.SUBMIT_SUCCESS:
            return { ...initialReminderState }
        default:
            return state;
    }
}