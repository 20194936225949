import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { APIMETHOD } from '../../../core/constant';
import { useAxios } from '../../../core/useAxios';
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import moment from "moment";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { Box, Switch } from '@mui/material';
import Button from "@mui/material/Button";
import { AddCircleOutline } from '@mui/icons-material';
import AddAction from './add-action';
import AlertMsg from '../../../shared/components/AlertMsg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { initialReminderState } from './modal';

function ActionReminderTab(props: any) {
  const { get, post, put, del } = useAxios({ showLoader: true });
  const [reminderList, setReminderList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [addAction, setAddAction] = useState<number>(0);
  const [isSendReminder, setIsSendReminder] = useState<boolean>(false);

  const [isConfirmation, setIsConfirmation] = useState(0);
  const [deleteReminderData, setDeleteRemainderData] = useState<any>({});  
  const [editReminderData, setEditRemainderData] = useState<any>({});

  const handleClose = useCallback(() => {
    setAddAction(0);
  }, [addAction]);

  useEffect(() => {
    const _getAllReminders = async () => {
      const res: any = await getAllReminders();
      if (res && res.results && res.results?.length > 0) {
        setReminderList(res.results);
      }
    }
    _getAllReminders();
  }, []);

  useEffect(() => {
    if (addAction!==0 && userList.length === 0 && clientList.length === 0) {
      getReminderUser();
      getReminderClient();
    }
  }, [addAction])

  const getAllReminders = async () => {
    const response: any = await get({
      url: APIMETHOD.reminder.GET_ALL,
    });
    if (response) {
      return response;
    }
  };
  const getReminderUser = async () => {
    const res = await getUserList();
    if (res && res?.results && res?.results?.length > 0) {
      setUserList(res?.results);
    } else {
      setUserList([])
    }
  }
  const getReminderClient = async () => {
    const res = await getClientList();
    if (res && res?.results && res?.results?.length > 0) {
      setClientList(res?.results);
    } else {
      setClientList([])
    }
  }
  const getUserList = async () => {
    const response: any = await get({
      url: APIMETHOD.reminder.REMINDER_USER,
    });
    if (response) {
      return response;
    }
  };
  const getClientList = async () => {
    const response: any = await get({
      url: APIMETHOD.clients.GET_CLIENT,
    });
    if (response) {
      return response;
    }
  };

  const submitAction = async (request: any) => {
    const reqData: any = {
      ...request,
      due_date: moment(request?.due_date).format("YYYY-MM-DD")
    }

    if (addAction === 1) {
      const result: any = await post({ url: APIMETHOD.reminder.ADD_REMINDER, data: reqData });
      if (result) {
        const list = [...reminderList, result];
        setAddAction(0);
        setReminderList(list);
      }
    } else if (addAction === 2) {      
      const result: any = await put({
        url: APIMETHOD.reminder.ADD_REMINDER + reqData?.id + '/',
        data: reqData
      });

      if (result) {
        const list = [...reminderList];
        const foundIndex = reminderList?.findIndex((x: any) => x.id === reqData?.id);
        list.splice(foundIndex, 1, result);
        setAddAction(0);
        setReminderList(list);
      }
    }
  }

  const handleActionCompleted = async (event: any, actionData: any) => {
    const reqData: any = {
      ...actionData.row,
      [event.target.name]: event.target.checked
    }
    const result: any = await put({
      url: APIMETHOD.reminder.ADD_REMINDER + reqData?.id + '/',
      data: reqData
    });

    if (result) {
      const list = [...reminderList];
      const foundIndex = reminderList?.findIndex((x: any) => x.id === reqData?.id);
      list.splice(foundIndex, 1, result);
      setReminderList(list);
    }
  };

  const handleAddReminder = () =>{
    setEditRemainderData(initialReminderState);
    setAddAction(1);
  }

  const handleEditReminder = (reminderData: any) => {    
    const reminder_object = {
      ...reminderData,
      due_date: new Date(reminderData.due_date)
    }
    setEditRemainderData(reminder_object);   
    setAddAction(2);
  }

  const showDeleteReminderConfirm = (reminderData: any) => {
    setDeleteRemainderData(reminderData);
    setIsConfirmation(1);
  }

  const confirmDiloagClose = () => {
    setIsConfirmation(0);
  };

  const confirmDelete = async () => {
    if (isConfirmation === 1) {
      const res: any = await del({ url: APIMETHOD.reminder.ADD_REMINDER + deleteReminderData?.id + '/' });

      if (!res) {
        const list = [...reminderList];
        const foundIndex = reminderList?.findIndex((x: any) => x.id === deleteReminderData?.id);
        list.splice(foundIndex, 1);
        setReminderList(list);
        confirmDiloagClose();
      }      
    }
  };  

  const sendReminder = async (reminderId: any) => {
    const result: any = await post({ url: APIMETHOD.reminder.SEND_REMINDER, data: { reminder_id: reminderId } });
    if (result) {
      setIsSendReminder(true);
    }
  }

  const columns: any = [
    {
      field: "client_name",
      headerName: "Client",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.5
    },
    {
      field: "assigned_to_name",
      headerName: "Assigned To",
      width: 150,
    },

    {
      field: "is_done",
      headerName: "Completed",
      flex: 0.5,
      renderCell: (params: any) => {
        return (<Switch
          checked={params?.value}
          onChange={(event) => handleActionCompleted(event, params)}
          name="is_done"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />)
      }
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1
    },
    {
      field: "id",
      headerName: "Reminder",
      sortable: false,
      flex: 0.5,
      renderCell: (params: any) => {
        return (
          <Box sx={{ justifyContent: 'center' }}>
            <Button
              size="small"
              variant="contained"
              sx={{ minWidth: "auto", p: 0.5, mr: 1 }}
              onClick={() => handleEditReminder(params.row)}
            >
              <EditIcon fontSize="small" />
            </Button>
            <Button
              size="small"
              color="error"
              variant="contained"
              sx={{ minWidth: "auto", p: 0.5, mr: 1 }}
              onClick={() => showDeleteReminderConfirm(params.row)}
            >
              <DeleteIcon fontSize="small" />
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{ minWidth: "auto", p: 0.5, mr: 2 }}
              onClick={() => { sendReminder(params.value) }}
            >
              <NotificationAddIcon fontSize="small" />
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <div>
      <Box sx={{ position: "absolute", top: "0px", right: "0" }}>
        <Button
          sx={{ mb: 1, mt: 1 }}
          variant="outlined"
          size="small"
          startIcon={<AddCircleOutline />}
          color="primary"
          onClick={() => { handleAddReminder() }}
        >
          Add Action
        </Button>
      </Box>
      <AlertMsg
        isOpen={isSendReminder}
        handleClose={() => setIsSendReminder(false)}
        message={`Reminder Sent Successfully!!!`}
        severity="success"
      />
      <ConfirmationDialog
        isOpen={isConfirmation !== 0}
        handleClose={confirmDiloagClose}
        handleConfirm={confirmDelete}
      />
      {
        addAction !== 0 && <AddAction
          addEdit={addAction}
          userList={userList}
          clientList={clientList}
          handleClose={handleClose}
          addActions={submitAction}
          reminderData={editReminderData} />
      }
      <Box sx={{ width: "100%", height: "auto", mb: 3 }}>
        <DataGrid
          rows={reminderList}
          sx={{ width: "100%" }}
          columns={columns}
          getRowId={(row: any) => row.id}
          autoHeight={true}
          disableSelectionOnClick
          density="compact"
          getRowClassName={(params: any) => {
            return params?.row?.is_done ? 'bg-green' : '';
          }}
        />
      </Box>
    </div>
  );
}

export default ActionReminderTab;