// Define action types
export enum ReminderActionTypes {
  FETCH_REMINDERS_REQUEST = "FETCH_REMINDERS_REQUEST",
  FETCH_REMINDERS_SUCCESS = "FETCH_REMINDERS_SUCCESS",
  FETCH_REMINDERS_FAILURE = "FETCH_REMINDERS_FAILURE",
  POST_REMINDER_REQUEST = "POST_REMINDER_REQUEST",
  POST_REMINDER_SUCCESS = "POST_REMINDER_SUCCESS",
  POST_REMINDER_FAILURE = "POST_REMINDER_FAILURE",
  PATCH_REMINDER_REQUEST = "PATCH_REMINDER_REQUEST",
  PATCH_REMINDER_SUCCESS = "PATCH_REMINDER_SUCCESS",
  PATCH_REMINDER_FAILURE = "PATCH_REMINDER_FAILURE",
  DELETE_REMINDER_REQUEST = "DELETE_REMINDER_REQUEST",
  DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS",
  DELETE_REMINDER_FAILURE = "DELETE_REMINDER_FAILURE",
}

// Define action interfaces
export interface FetchRemindersRequestAction {
  type: ReminderActionTypes.FETCH_REMINDERS_REQUEST;
}

export interface FetchRemindersSuccessAction {
  type: ReminderActionTypes.FETCH_REMINDERS_SUCCESS;
  payload: any[];
}

export interface FetchRemindersFailureAction {
  type: ReminderActionTypes.FETCH_REMINDERS_FAILURE;
  payload: string;
}

export interface PostReminderRequestAction {
  type: ReminderActionTypes.POST_REMINDER_REQUEST;
}

export interface PostReminderSuccessAction {
  type: ReminderActionTypes.POST_REMINDER_SUCCESS;
  payload: any;
}

export interface PostReminderFailureAction {
  type: ReminderActionTypes.POST_REMINDER_FAILURE;
  payload: string;
}

export interface PatchReminderRequestAction {
  type: ReminderActionTypes.PATCH_REMINDER_REQUEST;
}

export interface PatchReminderSuccessAction {
  type: ReminderActionTypes.PATCH_REMINDER_SUCCESS;
  payload: any;
}

export interface PatchReminderFailureAction {
  type: ReminderActionTypes.PATCH_REMINDER_FAILURE;
  payload: string;
}

export interface DeleteReminderRequestAction {
  type: ReminderActionTypes.DELETE_REMINDER_REQUEST;
}

export interface DeleteReminderSuccessAction {
  type: ReminderActionTypes.DELETE_REMINDER_SUCCESS;
  payload: any;
}

export interface DeleteReminderFailureAction {
  type: ReminderActionTypes.DELETE_REMINDER_FAILURE;
  payload: string;
}

// Action type union
export type ReminderAction =
  | FetchRemindersRequestAction
  | FetchRemindersSuccessAction
  | FetchRemindersFailureAction
  | PostReminderRequestAction
  | PostReminderSuccessAction
  | PostReminderFailureAction
  | PatchReminderRequestAction
  | PatchReminderSuccessAction
  | PatchReminderFailureAction
  | DeleteReminderRequestAction
  | DeleteReminderSuccessAction
  | DeleteReminderFailureAction;
