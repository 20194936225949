import { Dispatch } from "react";
import axios from "axios";
import { UserAction, UserActionTypes } from "./userActionTypes";
import { APIMETHOD } from "../../core/constant";

// Define async action for fetching all users
export const fetchUsers = async (
  dispatch: Dispatch<UserAction>,
  token: any
) => {
  dispatch({ type: UserActionTypes.FETCH_USERS_REQUEST });
  try {
    const response = await axios.get(APIMETHOD.user.GET_ALL, {
      headers: {
        Authorization: `Token ${token}`,
      }
    });
    const data = response.data;
    dispatch({
      type: UserActionTypes.FETCH_USERS_SUCCESS,
      payload: data.results,
    });
    return data
  } catch (error: any) {
    dispatch({
      type: UserActionTypes.FETCH_USERS_FAILURE,
      payload: error.message,
    });
  }
};

// Define async action for posting a user
export const postUser = async (
  dispatch: Dispatch<UserAction>,
  userData: any,
  token: any
) => {
  dispatch({ type: UserActionTypes.POST_USER_REQUEST });
  try {
    const response = await axios.post(
      APIMETHOD.user.ADD_USER,
      userData,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({
      type: UserActionTypes.POST_USER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: UserActionTypes.POST_USER_FAILURE,
      payload: error.message,
    });
  }
};

export const patchUser: any = async (
  dispatch: Dispatch<UserAction>,
  userData: any,
  token: any
) => {
  dispatch({ type: UserActionTypes.PATCH_USER_REQUEST });
  try {
    const response = await axios.patch(
      APIMETHOD.user.ADD_USER + userData?.id + '/',
      userData,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({
      type: UserActionTypes.PATCH_USER_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    dispatch({
      type: UserActionTypes.PATCH_USER_FAILURE,
      payload: error.message,
    });
    return error;
  }
};

export const deleteUser: any = async (
  dispatch: Dispatch<UserAction>,
  userData: any,
  token: any
) => {
  dispatch({ type: UserActionTypes.DELETE_USER_REQUEST });
  try {
    const response = await axios.delete(
      APIMETHOD.user.ADD_USER + userData?.id + '/',
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    const data = response.data;
    dispatch({
      type: UserActionTypes.DELETE_USER_SUCCESS,
      payload: userData,
    });
    return data;
  } catch (error: any) {
    dispatch({
      type: UserActionTypes.DELETE_USER_FAILURE,
      payload: error.message,
    });
    return error;
  }
};
