import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextareaAutosize } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { boardContext } from "../../../App";
import { stageList } from "../kanban-board/data";
import AlertMsg from "../../../shared/components/AlertMsg";
import { APIMETHOD } from "../../../core/constant";
import { useAxios } from "../../../core/useAxios";
import {
  createEmailTemplateString,
  extractEmailFieldsFromString,
} from "../../../core/emailTemplateStrings";

interface stageDiaglog {
  isOpen: boolean;
  handleClose: () => void;
  stageData?: any;
}
export default function EditStage(props: stageDiaglog) {
  const { isOpen, handleClose, stageData } = props;
  const [isEdited, setIsEdited] = useState(false);
  const [stageName, setStageName] = React.useState("");
  const [stageOrder, setStageOrder] = React.useState("");
  const [stageColor, setStageColor] = React.useState("");
  const [stageEmailFields, setStageEmailFields] = React.useState({
    to: "",
    cc: "",
    subject: "",
    body: "",
  });

  const { post, put, error } = useAxios({ showLoader: true });

  const { boardData, setBoardData, config } = useContext(boardContext);

  const boardLength: number = boardData?.columns?.length;

  const stage_List = stageList();

  useEffect(() => {
    if (JSON.stringify(stageData) !== "{}" && stageData) {
      setStageName(stageData.name);
      setStageOrder(stageData.order);
      setStageColor(stageData.color_hex);
      const retrievedData = extractEmailFieldsFromString(
        stageData?.email_fields || ""
      );
      setStageEmailFields(retrievedData);
    }
  }, [stageData]);

  const handleCloseTask = () => {
    handleClose();
  };

  const addStage = async () => {
    let data: any = [...boardData.columns];
    const emailFieldToEnter = createEmailTemplateString(
      stageEmailFields.to,
      stageEmailFields.cc,
      stageEmailFields.subject,
      stageEmailFields.body
    );
    const newStage: any = {
      name: stageName,
      order: stageOrder,
      color_hex: stageColor,
      is_active: true,
      send_to_email: "",
      cc_to_email: "",
      email_fields: emailFieldToEnter,
    };
    // return console.log("stage data ", stageData);

    if (stageData?.boardIndex > -1) {
      setIsEdited(true);
      newStage.id = stageData.id;
      // newStage.cards = stageData.cards;
      newStage.send_to_email = stageData.send_to_email;
      newStage.cc_to_email = stageData.cc_to_email;
      // data[stageData?.boardIndex] = newStage;
      const response: any = await EditStageAPICall(newStage);
      if (response) {
        response.cards = stageData.cards;
        data.splice(stageData?.boardIndex, 1);
        data.splice(newStage.order - 1, 0, response);
      }
    } else {
      const response: any = await addStageAPICall(newStage);
      if (response) {
        response.cards = [];
        data.splice(newStage.order - 1, 0, response);
      }
    }
    // data.map((x: any, index: number) => {
    //   x.stageOrder = index + 1;
    //   return x;
    // } );
    boardData.columns = [...data];
    const updatedBoard = { ...boardData };
    // setBoardData(updatedBoard);
    const updatedColumns = boardData.columns.slice().sort((p1: any, p2: any) => (p1.order > p2.order) ? 1 : (p1.order < p2.order) ? -1 : 0);
    setBoardData({ ...boardData, columns: updatedColumns });
    config.setData(updatedBoard);
    handleClose();
  };

  const addStageAPICall = async (data: any) => {
    const response = await post({
      url: APIMETHOD.stages.ADD_STAGE,
      data: data,
    });
    if (response) {
      return response;
    }
  };

  const EditStageAPICall = async (data: any) => {
    const response = await put({
      url: APIMETHOD.stages.ADD_STAGE + data.id + "/",
      data: data,
    });
    if (response) {
      return response;
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStageOrder(event.target.value);
  };

  return (
    <div>
      <AlertMsg
        isOpen={isEdited}
        handleClose={() => setIsEdited(false)}
        message={`All clients in stage "${stageData.name}" will be assigned to stage "${stageName}"`}
        severity="warning"
      />
      <Dialog open={isOpen} onClose={handleCloseTask} fullWidth={true}>
        <DialogTitle> Enter client details </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 0.5 }} fullWidth>
              <TextField
                margin="dense"
                id="sname"
                label="Stage Name"
                type="text"
                value={stageName}
                onChange={(e) => {
                  setStageName(e.target.value);
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
            </Box>

            <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
              <TextField
                margin="dense"
                id="scolor"
                label="Stage Color"
                type="text"
                value={stageColor}
                onChange={(e) => {
                  setStageColor(e.target.value);
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
            </Box>

            <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
              <TextField
                margin="dense"
                id="steto"
                label="Email To"
                type="text"
                value={stageEmailFields.to}
                onChange={(e) => {
                  // setStageColor(e.target.value);
                  setStageEmailFields((prev) => {
                    return { ...prev, to: e.target.value };
                  });
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
            </Box>
              <Box>
              <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                <TextField
                  margin="dense"
                  id="stecc"
                  label="Email cc"
                  type="text"
                  value={stageEmailFields.cc}
                  onChange={(e) => {
                    // setStageColor(e.target.value);
                    setStageEmailFields((prev) => {
                      return { ...prev, cc: e.target.value };
                    });
                  }}
                  fullWidth
                  variant="standard"
                  size="small"
                />
              </FormControl>
              </Box>

            {/* {stageEmailFields.cc && stageData?.id &&
              <Box>
              <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                <TextField
                  margin="dense"
                  id="stecc"
                  label="Email cc"
                  type="text"
                  value={stageEmailFields.cc}
                  onChange={(e) => {
                    // setStageColor(e.target.value);
                    setStageEmailFields((prev) => {
                      return { ...prev, cc: e.target.value };
                    });
                  }}
                  fullWidth
                  variant="standard"
                  size="small"
                />
              </FormControl>
              </Box>
            } */}

            <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
              <TextField
                margin="dense"
                id="stesubject"
                label="Email Subject"
                type="text"
                value={stageEmailFields.subject}
                onChange={(e) => {
                  // setStageColor(e.target.value);
                  setStageEmailFields((prev) => {
                    return { ...prev, subject: e.target.value };
                  });
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
            </Box>

            <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
              <TextField
                margin="dense"
                id="stebody"
                label="Email body"
                multiline
                rows={4}
                value={stageEmailFields.body}
                onChange={(e) => {
                  // setStageColor(e.target.value);
                  setStageEmailFields((prev) => {
                    return { ...prev, body: e.target.value };
                  });
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
            </Box>

            <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ m: 1 }} fullWidth>
              <InputLabel id="stagek">Stage Order</InputLabel>
              <Select
                labelId="stagek"
                id="stage"
                value={stageOrder}
                onChange={handleChange}
                label="Select stage"
              >
                {Array.from(
                  { length: boardData.columns?.length + 1 },
                  (_, i) => i + 1
                )?.map((s: any) => (
                  <MenuItem key={s.toString()} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTask}>Cancel</Button>
          <Button onClick={addStage}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
