import { BorderBottom, Height } from "@mui/icons-material";
import { Divider, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import TaskList from "./tasklist";
import { useDrop } from "react-dnd";
import { getBoardTaskList, ItemTypes } from "./data";
import { boardContext } from "../../../App";
import Emailtemplate from "./Emailtemplate";
import { useAxios } from "../../../core/useAxios";
import { APIMETHOD } from "../../../core/constant";
import { extractEmailFieldsFromString } from "../../../core/emailTemplateStrings";
import moment from "moment";

interface TaskBoardProps {
  cardDetails: any;
  stageNum: number;
  boardIndex: number;
  systemWidth: number;
}

const EMAIL_DETAILS: any = {
  from: "",
  to: "",
  cc: "",
  subject: "",
  body: "",
};

function TaskBoard(props: TaskBoardProps) {
  const { id, name, cards, stageOrder, color_hex } = props.cardDetails;
  const [sendEmail, setSendEmail] = useState(false);
  const [emailDetails, setEmailDetails] = useState<any>(EMAIL_DETAILS);
  const { stageNum, boardIndex, systemWidth } = props;
  const { get, put, error } = useAxios({ showLoader: true });

  const { boardData, setBoardData, config } = React.useContext(boardContext);

  const numberOfStage = boardData?.columns?.length || 0;

  const defaultStage = boardData.defaultStageNo;

  const [itemDetails, setItemDetails] = useState({ clients: {id: null}, prevStageIndex: null });

  const [doneDontMove, setDoneDontMove] = useState(true);

  const [taskDetails, setTaskDetails] = useState({id: null});

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.TASK,
    drop: (item: any) => dropedItem(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const dropedItem = async (item: any) => {
    const updatedDragedItem: any = boardData?.columns[item?.prevStageIndex]?.cards[item?._taskIndex];
    let updatedClients: any = item.clients;
    if(updatedDragedItem && updatedDragedItem?.id === item?.clients?.id) {
      updatedClients = updatedDragedItem;
    }
    updatedClients.stage = boardData.columns[boardIndex]?.id;

    // Check if the item is moved to the 3rd column (you can adjust based on actual column index or id)
    if (updatedClients.stage === 42) {
      updatedClients.client_status = 1; // Apply green color immediately
    }

    const response = await EditClientsAPICall(updatedClients);
    const curStage = await get({
      url: APIMETHOD.stages.GET_STAGE_BY_ID.replace(":id", id),
    });
    const emailFieldsObj = extractEmailFieldsFromString(
      curStage?.email_fields || ""
    );

    setItemDetails(item)

    if (response) {
      const data = { ...updatedClients, stage: updatedClients.stage };
      const boardTaskList = getBoardTaskList(boardData, item.prevStageIndex);
      const prevBoardTaskList = boardTaskList.filter(
        (x: any) => x.id !== item.clients.id
      );
      boardData.columns[item.prevStageIndex].cards = prevBoardTaskList;
      boardData.columns[boardIndex].cards.push(data);

      setEmailInfo({ ...emailFieldsObj }, boardData?.from_to_email, item?.clients?.client_name);
      setDoneDontMove(true)
      const updatedData = { ...boardData };
      setBoardData(updatedData);
      config.setData(updatedData);

      if (updatedClients.stage === 7 || updatedClients.stage === 13){
        const resp = await get({
          url: APIMETHOD.UPDATE_SALES_REPORT_DROP, 
          params: {
            main_address_country: updatedClients.main_address_country, 
            stage: updatedClients.stage,
            proposal_amount: updatedClients.proposal_amount
          }
        });
      }
    }
  };

  const handleDontMove = async () => {
    if (itemDetails && itemDetails.prevStageIndex !== null) {
      let updatedClients: any = itemDetails.clients;
      updatedClients.stage = boardData.columns[itemDetails?.prevStageIndex]?.id;
      const response = await EditClientsAPICall(updatedClients);
      if (response) {
        const data = { ...updatedClients, stage: updatedClients.stage };
        const boardTaskList = getBoardTaskList(boardData, boardIndex);
        const prevBoardTaskList = boardTaskList.filter(
          (x: any) => x.id !== itemDetails.clients.id
        );
        boardData.columns[boardIndex].cards = prevBoardTaskList;
        boardData.columns[itemDetails?.prevStageIndex].cards.push(data);

        const updatedData = { ...boardData };
        setBoardData(updatedData);
        config.setData(updatedData);
      }
    }
  };

  const handleDontMoveDone = async () => {
    let updatedClients: any = taskDetails;
    updatedClients.stage = id;
    const response = await EditClientsAPICall(updatedClients);
    if (response) {
      const data = { ...updatedClients, stage: updatedClients.stage };
      if (boardIndex === (boardData?.columns.length || 0) - 2) {
        const waiting_index: number = boardData?.columns?.findIndex(
            (x: any) => x.id === (boardData.waiting_stage || -1)
          );

        const boardTaskList = getBoardTaskList(boardData, waiting_index);
        const prevBoardTaskList = boardTaskList.filter(
          (x: any) => x.id !== taskDetails.id
        );
        boardData.columns[waiting_index].cards = prevBoardTaskList;
      }
      else {
        const boardTaskList = getBoardTaskList(boardData, boardIndex+1);
        const prevBoardTaskList = boardTaskList.filter(
          (x: any) => x.id !== taskDetails.id
        );
        boardData.columns[boardIndex+1].cards = prevBoardTaskList;
      }
      boardData.columns[boardIndex].cards.push(data);

      const updatedData = { ...boardData };
      setBoardData(updatedData);
      config.setData(updatedData);
    }
  }

  const EditClientsAPICall = async (data: any) => {
    data.movement_date = moment().format('YYYY-MM-DD');
    const response: any = await put({
      url: APIMETHOD.clients.EDIT_CLIENT + data.id + "/",
      data: data,
    });
    if (response) {
      return response;
    }
    return "";
  };

  const getWidth = () => {
    let w = numberOfStage > 10 ? systemWidth / 10 : systemWidth / numberOfStage;
    if (defaultStage <= 10) {
      w = systemWidth / defaultStage;
    }
    if (numberOfStage <= 4) {
      return 290;
    }
    return w - 15;
  };

  const setEmailInfo = (data: any, fromAdress: any, clientName: any) => {
    const emaildata: any = {
      from: fromAdress || "",
      to: data.to || "",
      cc: data.cc || "",
      // subject: data.subject || "",
      subject: (data.subject || "") + " (Client: " + (clientName || "") +")",
      body: data.body || "",
    };

    setEmailDetails(emaildata);
    openEmailTemplate();
  };

  const isDoneClick = async (data: boolean, emailInfo: any, fromAdress: any, clientName: any) => {
    const curStage = await get({
      url: APIMETHOD.stages.GET_STAGE_BY_ID.replace(":id", emailInfo.id),
    });
    const emailFieldsObj = extractEmailFieldsFromString(
      curStage?.email_fields || ""
    );
    setEmailInfo({ ...emailFieldsObj }, fromAdress, clientName);
    setDoneDontMove(false)
  };

  const openEmailTemplate = () => {
    setSendEmail(true);
    // setTimeout(() => {

    // }, 1000);
  };

  return (
    <>
      {sendEmail ? (
        <Emailtemplate
          isOpenEmail={sendEmail}
          handleCloseEmail={setSendEmail}
          emailDetails={emailDetails}
          source="taskboard"
          dontMoveHandler={handleDontMove}
          doneDontMove={doneDontMove}
          dontMoveHandlerDone={handleDontMoveDone}
        />
      ) : null}
      <Box
        ref={drop}
        key={id}
        sx={{
          display: "flex",
          "& > :not(style)": {
            my: 1,
            mx: 0.5,
            width: getWidth(),
            minHeight: 200,
            border: "3px solid " + color_hex,
            borderRight: 0,
            borderBottom: 0,
            borderRadius: "5px",
          },
        }}
      >
        <Paper
          variant="outlined"
          square
          sx={{
            height: "calc(100vh - 80px)",
            overflowY: "auto",
            background: "#F5F5F5",
            position: "relative",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: color_hex,
              borderRadius: "2px",
              padding: "5px",
              marginLeft: "-1px",
              color: "#fff",
              position: "sticky",
              top: "0",
              zIndex: 2,
            }}
          >
            {name}
          </Box>
          <Divider />
          {cards.map((task: any, index: number) => {            
            return (
              <div key={task.id + "1"}>
                <TaskList
                  task={task}
                  stageId={id}
                  stageNum={stageNum}
                  boardIndex={boardIndex}
                  taskIndex={index}
                  isDoneClick={isDoneClick}
                  setTaskDetails={setTaskDetails}
                />
              </div>
            );
          })}
          {isOver && (
            <Box
              sx={{
                textAlign: "center",
                border: "2px solid green",
                padding: "0.5rem",
                margin: "0.5rem",
                minHeight: "5rem",
              }}
            >
              <Box
                sx={{
                  border: "2px dotted green",
                  padding: "2rem",
                  minHeight: "5rem",
                }}
              >
                Drop Here
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
}

export default TaskBoard;
