// Define action types
export enum UserActionTypes {
  FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE",
  POST_USER_REQUEST = "POST_USER_REQUEST",
  POST_USER_SUCCESS = "POST_USER_SUCCESS",
  POST_USER_FAILURE = "POST_USER_FAILURE",
  PATCH_USER_REQUEST = "PATCH_USER_REQUEST",
  PATCH_USER_SUCCESS = "PATCH_USER_SUCCESS",
  PATCH_USER_FAILURE = "PATCH_USER_FAILURE",
  DELETE_USER_REQUEST = "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "DELETE_USER_FAILURE",
}

// Define action interfaces
export interface FetchUsersRequestAction {
  type: UserActionTypes.FETCH_USERS_REQUEST;
}

export interface FetchUsersSuccessAction {
  type: UserActionTypes.FETCH_USERS_SUCCESS;
  payload: any[];
}

export interface FetchUsersFailureAction {
  type: UserActionTypes.FETCH_USERS_FAILURE;
  payload: string;
}

export interface PostUserRequestAction {
  type: UserActionTypes.POST_USER_REQUEST;
}

export interface PostUserSuccessAction {
  type: UserActionTypes.POST_USER_SUCCESS;
  payload: any;
}

export interface PostUserFailureAction {
  type: UserActionTypes.POST_USER_FAILURE;
  payload: string;
}

export interface PatchUserRequestAction {
  type: UserActionTypes.PATCH_USER_REQUEST;
}

export interface PatchUserSuccessAction {
  type: UserActionTypes.PATCH_USER_SUCCESS;
  payload: any;
}

export interface PatchUserFailureAction {
  type: UserActionTypes.PATCH_USER_FAILURE;
  payload: string;
}

export interface DeleteUserRequestAction {
  type: UserActionTypes.DELETE_USER_REQUEST;
}

export interface DeleteUserSuccessAction {
  type: UserActionTypes.DELETE_USER_SUCCESS;
  payload: any;
}

export interface DeleteUserFailureAction {
  type: UserActionTypes.DELETE_USER_FAILURE;
  payload: string;
}

// Action type union
export type UserAction =
  | FetchUsersRequestAction
  | FetchUsersSuccessAction
  | FetchUsersFailureAction
  | PostUserRequestAction
  | PostUserSuccessAction
  | PostUserFailureAction
  | PatchUserRequestAction
  | PatchUserSuccessAction
  | PatchUserFailureAction
  | DeleteUserRequestAction
  | DeleteUserSuccessAction
  | DeleteUserFailureAction;
