import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import AlertMsg from "../../../shared/components/AlertMsg";

interface userDiaglog {  
  userData?: any;
  handleClose: () => void;
  handleSubmit: (userData:any) => void;  
}
export default function EditUser(props: userDiaglog) {
  const { userData, handleSubmit, handleClose } = props;
  const [isEdited, setIsEdited] = useState(false);
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");

  useEffect(() => {
    if (userData) {
      setUserName(userData.name);
      setUserEmail(userData.email);      
    }
  }, [userData]);

  const handleCloseTask = () => {
    handleClose();
  };

  const submitClick = async () => {
    userData.name = userName;
    userData.email = userEmail;
    handleSubmit(userData);
  };
  
  return (
    <div>
      <AlertMsg
        isOpen={isEdited}
        handleClose={() => setIsEdited(false)}
        message={`All clients in stage "${userName}" will be assigned to stage "${userName}"`}
        severity="warning"
      />
      <Dialog  open= {true} onClose={handleCloseTask}
        sx={{ '& .MuiDialog-paper': { width: '80%' } }}
        maxWidth="xs">
        <DialogTitle> Enter user details </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 0.5 }} fullWidth>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
          </Box>
          <Box sx={{ mr: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                fullWidth
                variant="standard"
                size="small"
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTask}>Cancel</Button>
          <Button onClick={submitClick}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
