import ActionReminderTab from "./action-tab";
import ConfigPage from "./config-tab";
import KanbanBoard from "./kanban-board";
import Users from "./users";

interface TAB_CONFIG {
    label: string,
    component: any,
    value: any,
    id: any
};

export const Tabs_List: Array<TAB_CONFIG> = [
    {
        label: 'Kanban',
        component: KanbanBoard,
        value: '1',
        id: 'tab1'
    },
    {
        label: 'Config',
        component: ConfigPage,
        value: '2',
        id: 'tab2'
    },
    {
        label: 'Action',
        component: ActionReminderTab,
        value: '3',
        id: 'tab3'
    },
    {
        label: 'Users',
        component: Users,
        value: '4',
        id: 'tab4'
    }
]
export {};