import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, TextField } from '@mui/material';
import { emailTemplateConfig } from '../../../utils';
import { APIMETHOD } from '../../../core/constant';
import { useAxios } from '../../../core/useAxios';
// import { DisplayFormikState } from './formikHelper';

interface EmailProps {
  isOpenEmail: boolean,
  handleCloseEmail: (data: any) => void,
  emailDetails: any
  source?: string; // Make source optional
  namedEmail?: string;
  dontMoveHandler?: () => void,
  doneDontMove?: boolean,
  dontMoveHandlerDone?: () => void,
}

function Emailtemplate(props: EmailProps) {
  // const [open, setOpen] = useState(false);
  const { isOpenEmail, handleCloseEmail, emailDetails, source, namedEmail, dontMoveHandler, doneDontMove, dontMoveHandlerDone } = props
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const formdata: any = useRef(null);
  const { post, put, error } = useAxios({showLoader: true});

  const handleCloseTask = () => {
    handleCloseEmail(false);
  };

  const handleUndoMove = () => {
    if (dontMoveHandler){
      dontMoveHandler();
      handleCloseEmail(false);
    }
  }

  const handleUndoMoveDone = () => {
    if (dontMoveHandlerDone){
      dontMoveHandlerDone();
      handleCloseEmail(false);
    }
  }

  const handleSubmit = async (values: any) => {
    const request: any = {
      from_email: values.from,
      to_email: values.to ? values.to.split(',') : values.to,
      cc_email: values.cc ? values.cc.split(',') : [],
      subject: values.subject,
      body: values.comment,
      named_email: namedEmail
    }
    const response: any = await addClientsAPICall(request);
    if(response) {

    }
    handleCloseEmail(false);
  }

  // const addClientsAPICall = async(data: any) => {
  //   const response: any = await post({url: APIMETHOD.SEND_EMAIL, data: data});
  //   if(response) {
  //     return response;
  //   }
  // }

  const addClientsAPICall = async (data: any) => {
    if (source === 'tasklist') {
      // Call the tasklist API function
      const response: any = await post({ url: APIMETHOD.SEND_EMAIL_TASKLIST, data: data });
      if (response) {
        return response;
      }
    } else {
      // Call the other API function
      const response: any = await post({url: APIMETHOD.SEND_EMAIL, data: data});
      if (response) {
        return response;
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpenEmail}
        onClose={handleCloseTask}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted &&
          <React.Fragment>
            <DialogContent>
              <DialogContentText>
                Send us a email!
              </DialogContentText>
              <Formik
                enableReinitialize
                initialValues={{ from: emailDetails.from, to: emailDetails.to, cc: emailDetails.cc, subject: emailDetails.subject, comment: emailDetails.body }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  setSubmitionCompleted(true);
                  handleSubmit(values);
                }}

                validationSchema={Yup.object().shape({
                    from: Yup.string()
                        .email()
                        .required('Please enter above field'),
                    to: Yup.array()
                      .transform(function(value, originalValue) {
                        if (this.isType(value) && value !== null) {
                          return value;
                        }
                        return originalValue ? originalValue.split(/[\s,]+/) : [];
                      })
                      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
                      .required('Please enter sender email'),
                    cc: Yup.array()
                      .transform(function(value, originalValue) {
                        if (this.isType(value) && value !== null) {
                          return value;
                        }
                        return originalValue ? originalValue.split(/[\s,]+/) : [];
                      })
                      .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
                    subject: Yup.string()
                        .required('Please enter subject'),
                    comment: Yup.string()
                        .required(`Body can't be empty`),
                })}
              >
                {(props: any) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} ref={formdata} noValidate>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'email'}
                                variant="standard"
                                label="From"
                                name="from"
                                value={values.from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={(errors.from && touched.from) && errors.from}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'email'}
                                variant="standard"
                                label="To"
                                name="to"
                                value={values.to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={(errors.to && touched.to) && errors.to}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'email'}
                                variant="standard"
                                label="Cc"
                                name="cc"
                                value={values.cc}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={(errors.cc && touched.cc) && errors.cc}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{  }} fullWidth>
                            <TextField
                                variant="standard"
                                error={errors.subject && touched.subject}
                                label="Subject"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={(errors.subject && touched.subject) && errors.subject}
                                margin="dense"
                                size="small"
                        />
                        </FormControl>
                      
                        <FormControl variant="standard" sx={{ }} fullWidth>
                        <TextField
                            error={errors.comment && touched.comment}
                            id="outlined-multiline-static"
                            label=""
                            name="comment"
                            onChange={handleChange}
                            value={values.comment}
                            multiline
                            rows={4}
                            helperText={(errors.comment && touched.comment) && errors.comment}
                            margin="dense"
                            size="small"
                            // InputProps={{
                            //   endAdornment: (
                            //     <React.Fragment>
                            //      {source === 'tasklist' && (
                            //           <div style={{ display: 'flex'}}>
                            //               <img src="/company_logo.png" alt="Client Logo" style={{ maxWidth: '100%', marginRight: '-50px' }} />
                            //           </div>
                            //       )}
                            //     </React.Fragment>
                            //   ),
                            // }}
                        />
                        {source === 'tasklist' && (
                          <div style={{ display: 'flex'}}>
                              <img src="/company_logo.png" alt="Client Logo" />
                          </div>
                        )}
                        </FormControl>
                      <DialogActions>
                        {source==="taskboard" && doneDontMove && (
                          <Button 
                            type="button" 
                            onClick={handleUndoMove}
                          >
                            Don't Move
                          </Button>
                        )}
                        {source==="taskboard" && !doneDontMove && (
                          <Button 
                            type="button" 
                            onClick={handleUndoMoveDone}
                          >
                            Don't Move
                          </Button>
                        )}
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleCloseTask}
                          // disabled={!dirty || isSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                        {/* <DisplayFormikState {...props} /> */}
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        }
      </Dialog>
    </React.Fragment >
  );
}
export default Emailtemplate;