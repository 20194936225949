import React from "react";
import TabDynamic from "../../shared/components/tabs";
import { Tabs_List } from "./model";
import { Box, spacing } from "@mui/system";
import SignIn from "../signin/SignIn";
import { boardContext } from "../../App";

function ClientRegister() {
  const { config } = React.useContext(boardContext);
  const token: any = config?.getToken();
  const tab = Tabs_List;

  // const handleAuth = (auth: boolean) => {
  //     setAuth(auth);
  // }
  return (
    <>
      {!token ? (
        <SignIn />
      ) : (
        <Box sx={{ mt: "1rem" }}>
          {/* <h3 className='mb-4'>Client Register</h3> */}
          <TabDynamic tabs={tab} />
        </Box>
      )}
    </>
  );
}

export default ClientRegister;
