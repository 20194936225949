export default {
    ADD_TASK: {
        CLIENT_NAME: 'Client name',
        SELECT_STAGE: 'Select stage',
        STAGE: 'Stage',
        SALESPERSON: 'Salesperson',
        REPRESENTATIVE_NAME: 'Representative name',
        CONTACT_EMAIL: 'Contact email',
        APPLICATION_DATE: 'Application date',
        JOB_WON_DATE: 'Job won date',
        CONTACT_PHONE: 'Contact phone',
        MAIN_STREET: 'Main street',
        MAIN_CITY: 'Main city',
        MAIN_STATE: 'Main state',
        MAIN_ZIP: 'Main zip',
        MAIN_COUNTRY: 'Main country',
        NUMBER_LOCATION: 'Number of locations',
        WEBSITE: 'Website',
        SCOPE_REGISTRATION: 'Scope of registration',
        NUMBER_TEMPORARY_SITE: 'Number of temporary sites',
        STANDARDS: 'Standards',
        OPERATION_PROCESS: 'Operational processes',
        OUTSOURCED_PROCESS: 'Outsourced processes',
        AUDIT_ANIVERSARY: 'Audit anniversary',
        ASSIGNED_AUDITOR:'Assigned auditor',
        REFERED_BY: 'Original Referrer',
        REFERED_BY_2: 'Second Referrer',
        DAY_RATE: 'Day rate',
        PROPOSAL_AMOUNT: 'Proposal Amount',
        TOTAL_NO_OF_FULL_TIME_EQ_EMPLOYEES: 'Total No. of Full Time Equivalent Employees',
        SHIFTS: 'Shift',
        ACTIVITES_AT_SHIFT: 'Activities & No. of Employees in Shift',
        STAGE_ONE: 'Stage 1 Audit Days',
        STAGE_TWO: 'Stage 2 Audit Days',
        IS_MULTISITE: 'Is Multi site',
        REASON_FOR_LOST_CLIENT: 'Reason for lost client',
        EXCLUSIONS: 'Exclusions',
        MOBILE_PHONE_NUMBER: 'Mobile Phone number',
        SELECT_LEGAL_STATUS: 'Select Legal status',
        LEGAL_STATUS: 'Legal status',
        STATUTORY_REGULATORY_REQUIREMENTS: 'Statutory & Regulatory requirements',
        SELECT_OTHER_REGISTRATIONS: 'Select Other registrations',
        OTHER_REGISTRATIONS: 'Other registrations',
        PREVIOUS_CERTIFICATIONS: 'Previous certifications',
        CONSULTANT: 'Consultant?',
        SELECT_IAF_CODE: 'Select IAF Code',
        IAF_CODE: 'IAF Code',
        REPRESENTATIVE_ROLE: 'Representative Role',
        LATEST_AUDIT_COMPLETION_DATE: 'Latest audit completion date',
        MATURITY_OF_MANAGEMENT_SYSTEMS: 'Maturity of Management Systems',
        CLIENT_PREPAREDNESS: 'Client Preparedness',
        HIGH_LEVEL_OF_AUTOMATION: 'High Level of Automation',
        OFF_LOCATION_PERSONNEL: 'Off-location Personnel',
        REQUIRES_INTERPRETER: 'Requires Interpreter',
        VISITING_TEMPORARY_SITES: 'Visiting Temporary Sites',
        HIGH_DEGREE_OF_REGULATION: 'High Degree of Regulation',
        ALREADY_CERTIFIED_STANDARDS: 'Already Certified Standards',
        SIZE_OF_SITE_FOR_NUMBER_OF_PERSONNEL: 'Size of Site for Number of Personnel',
        DESIGN_RESPONSIBLE: 'Design Responsible',
        OUTSOURCED_FUNCTIONS: 'Outsourced Functions or Processes',
        RISK_CATEGORY: 'Risk Category',
        CUSTOMERS_WITH_CRITICAL_INFO: 'Customers with critical information',
        IT_INFRASTRUCTURE_COMPLEXITY: 'IT infrastructure complexity',
        DEPENDENCY_ON_OUTSOURCING_SUPPLIERS: 'Dependency on outsourcing suppliers including cloud services',
        INFORMATION_SYSTEM_DEV_IN_HOUSE: 'Information system development in house',
        OUTSOURCED_FUNCTIONS_EMS : 'EMS Outsourced Functions or Processes',
        HIGHER_SENSITIVITY_OF_ENVIRONMENT : 'Higher Sensitivity of Environment Compared to Typical for the Business Sector',
        ADDITIONAL_OR_UNUSUAL_ENVIRONMENTAL_ASPECTS_OR_REGULATED_CONDITIONS: 'Additional or Unusual Environmental Aspects or Regulated Conditions',
        HIGH_RISK_IF_ACCIDENT_INCIDENT_OR_EMERGENCY : 'High Risk if Accident Incident or Emergency',
        VIEWS_OF_INTERESTED_PARTIES : 'Views of interested parties',
        RATE_OF_ACCIDENTS : 'Rate of Accidents/Diseases Higher Compared to Typical for the Business Sector',
        PUBLIC_PRESENCE : 'Public are Present on the Site e.g Hospitals, Schools, etc...',
        LEGAL_PROCEEDINGS : 'Facing Legal Proceedings',
        LARGE_PRESENCE_OF_SUBCONTRACTORS : 'Temporary Large Presence of many Subcontractors e.g. Periodic Shutdowns',
        DANGEROUS_SUBSTANCES : 'Dangerous Substances Present Higher Compared to Typical for the Business Sector',
        UNKNOWN_LEGISLATION_AND_LANGUAGE : 'Sites and other countries where legislation and language are not well known',
        VIEWS_OF_INTERESTED_PARTIES_OHSMS : 'OHSMS Views of interested parties',
        MOVEMENT_DATE: 'Movement date',
        LIKELIHOOD_INDICATOR: 'Likelihood indicator',
        SELECT_LIKELIHOOD_INDICATOR: 'Select Likelihood indicator',
        TIMEFRAME_INDICATOR: 'Timeframe indicator',
        AUDIT_START_DATE: 'Audit Start Date',
        CERTIFICATION_BODY: 'Certification Body',
        CLIENT_STATUS: 'Client Status',
        SELECT_CLIENT_STATUS: 'Select Client Status',
    }
}
