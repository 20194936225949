import {
  Alert,
  Box,
  Button,
  FormControl,
  Link,
  TextField,
} from "@mui/material";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
// const fs = require('fs');
import { boardContext } from "../../../App";
import { AddCircleOutline } from "@mui/icons-material";
import EditStage from "./editstage";
import AlertMsg from "../../../shared/components/AlertMsg";
import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";
import { APIMETHOD } from "../../../core/constant";
import { useAxios } from "../../../core/useAxios";

let paramData: any;

const initialState = {
  defaultStage: 5,
  from_to_email: "damon@qfscertifications.com",
  move_to_lost: 0,
  waiting_stage: 0,
};

const reducerFn = (state: any, action: any) => {
  switch (action.type) {
    case "DEFAULTSTAGE":
      return { ...state, defaultStage: action.payload };
    case "FROMEMAIL":
      return { ...state, from_to_email: action.payload };
    case "LOST":
      return { ...state, move_to_lost: action.payload };
    case "WAITING":
      return { ...state, waiting_stage: action.payload };
    case "ALL":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

function ConfigPage() {
  const { boardData, setBoardData, config } = useContext(boardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isStageDelete, setIsStageDelete] = useState(false);
  const [singleBoardData, setSingleBoardData] = useState({});
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [state, dispatch] = useReducer(reducerFn, initialState);
  const [defaultStage, setDefaultStage] = useState(5);
  const [from_to_email, setFormEmail] = useState("");
  const { del, error, post, put } = useAxios({ showLoader: true });
  const boardList = boardData?.columns || [];

  //   console.log("Board List ", boardList);

  const rows = boardList;
  const columns: any = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Stage Name",
      width: 150,
    },
    {
      field: "color_hex",
      headerName: "Stage Color",
      width: 150,
    },
    {
      field: "order",
      headerName: "Stage Order",
      type: "number",
      width: 100,
    },
    {
      field: "email_fields",
      headerName: "Email Fields",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: "10px" }}
              size="small"
              onClick={() => onEditClick(params)}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="warning"
              size="small"
              onClick={() => onDeleteClick(params)}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch({
      type: "ALL",
      payload: {
        defaultStage: boardData?.defaultStageNo || 5,
        from_to_email: boardData?.from_to_email,
        move_to_lost: boardData?.move_to_lost || 0,
        waiting_stage: boardData?.waiting_stage || 0,
      },
    });
  }, [from_to_email]);

  const onEditClick = (params: any) => {
    const stageIndex = params.api.getRowIndex(params.row.id);
    editBoardDetails(params.row, Number(stageIndex));
  };

  const onDeleteClick = (params: any) => {
    paramData = params;
    setIsConfirmation(true);
  };
  const confirmDelete = () => {
    const params = paramData;
    if (params) {
      const stageIndex = params.api.getRowIndex(params.row.id);
      deleteStage(params.row, stageIndex);
      paramData = undefined;
      confirmDiloagClose();
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
    setSingleBoardData({});
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const editBoardDetails = (data: any, boardIndex: number) => {
    setSingleBoardData({ ...data, boardIndex: boardIndex });
    setIsOpen(true);
  };

  const confirmDiloagClose = () => {
    setIsConfirmation(false);
  };

  const deleteStage = async (datas: any, boardIndex: number) => {
    if (datas.cards.length > 0) {
      setIsStageDelete(true);
    } else {
      const response: any = await deleteStageAPICall(datas.id);
      if (!response) {
        const data = [...boardData.columns];
        data.splice(boardIndex, 1);
        boardData.columns = [...data];
        const updatedBoard = { ...boardData };
        setBoardData(updatedBoard);
        config.setData(updatedBoard);
      }
    }
  };

  const deleteStageAPICall = async (id: any) => {
    const response: any = await del({
      url: APIMETHOD.stages.ADD_STAGE + id + "/",
    });
    return response;
  };

  const handleChange = async (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e: any) => {
      const result = JSON.parse(e.target.result);
      const res: any = { ...boardData, ...result };
      setBoardData(res);
      // config.setData(result);
    };
  };

  const handleStageChange = (e: any) => {
    let data: any = e.target.value;
    if (e.target.value > 10) {
      data = 10;
    } else if (e.target.value < 1) {
      data = 1;
    } else {
      data = e.target.value;
    }
    dispatch({ type: "DEFAULTSTAGE", payload: data });
  };

  const setStage = () => {
    boardData.defaultStageNo = Number(defaultStage);
    config.setData(boardData);
  };

  const setConfig = async () => {
    const data: any = {
      key: "config_data",
      value: {
        defaultStageNo: Number(state.defaultStage),
        from_to_email: state.from_to_email,
        move_to_lost: Number(state.move_to_lost) || 0,
        waiting_stage: Number(state.waiting_stage) || 0,
      },
    };
    let response: any;
    if (boardData?.kvId) {
      response = await editKV(data, boardData?.kvId);
    } else {
      response = await createKV(data);
    }

    if (response) {
      const updateData = { ...boardData, ...data.value };
      setBoardData(updateData);
      config.setData(updateData);
    }
  };

  const createKV = async (data: any) => {
    const res: any = await post({ url: APIMETHOD.kv.CREATE, data });
    return res;
  };

  const editKV = async (data: any, kvId: number) => {
    const res: any = await put({ url: APIMETHOD.kv.CREATE + kvId + "/", data });
    return res;
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isConfirmation}
        handleClose={confirmDiloagClose}
        handleConfirm={confirmDelete}
      />
      <AlertMsg
        isOpen={isStageDelete}
        handleClose={() => setIsStageDelete(false)}
        message="Stage Can not be deleted because it has some clients"
        severity="error"
      />
      <Box sx={{ position: "absolute", top: "-10px", right: "0" }}>
        <Button
          sx={{ mb: 1, mt: 1 }}
          variant="outlined"
          size="small"
          startIcon={<AddCircleOutline />}
          color="primary"
          onClick={handleOpen}
        >
          Add Stage
        </Button>

        <Button
          variant="contained"
          component="label"
          sx={{ m: 1 }}
          size="small"
        >
          Upload
          <input
            hidden
            type="file"
            accept=".json"
            onChange={(e) => handleChange(e)}
          />
        </Button>

        <Button
          variant="contained"
          component="label"
          sx={{ m: 1 }}
          size="small"
        >
          <Link
            color={"#fff"}
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(boardData, null, 2)
            )}`}
            underline="none"
            download="task.json"
          >
            Download File
          </Link>
        </Button>
      </Box>

      {isOpen ? (
        <EditStage
          isOpen={isOpen}
          handleClose={handleClose}
          stageData={singleBoardData}
        />
      ) : null}
      {/* <Grid container spacing={{ xs: 2, md: 4 }}>
                {boardList.map((board: any, index: any) => {
                    return (
                        <Grid item xs={1} sm={6} md={3} lg={3} key={JSON.stringify(board.id)}>
                            <Boardconfig boardDetails = {board} editBoardDetails = {editBoardDetails} deleteStage={deleteStage} boardIndex={index}/>
                        </Grid>
                    )
                })}
            </Grid> */}
      <div>
        <Box sx={{ width: "100%", height: "auto", mb: 3 }}>
          <DataGrid
            rows={rows}
            sx={{ width: "100%" }}
            columns={columns}
            getRowId={(row: any) => row.id}
            autoHeight={true}
            disableSelectionOnClick
            density="compact"
          />
        </Box>
      </div>

      <div>
        <Box sx={{ width: "50%", mb: 3 }}>
          <h5>Board Config</h5>
          <FormControl variant="standard" sx={{ mb: 2 }} fullWidth>
            <TextField
              type={"number"}
              size="small"
              sx={{ mb: 2 }}
              value={state.defaultStage}
              InputProps={{ inputProps: { min: 1, max: 10 } }}
              onChange={(e: any) => handleStageChange(e)}
              id="defaultitem"
              label="No of Stage on screen "
              variant="standard"
            />
            <TextField
              type={"email"}
              size="small"
              sx={{ mb: 2 }}
              value={state.from_to_email}
              InputProps={{ inputProps: { min: 1, max: 10 } }}
              onChange={(e: any) =>
                dispatch({ type: "FROMEMAIL", payload: e.target.value })
              }
              id="defaultitem"
              label="From email"
              variant="standard"
            />
            <TextField
              type={"number"}
              size="small"
              sx={{ mb: 2 }}
              value={state.move_to_lost}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e: any) =>
                dispatch({ type: "LOST", payload: e.target.value })
              }
              id="defaultitem"
              label="Lost Stage"
              variant="standard"
            />
            <TextField
              type={"number"}
              size="small"
              value={state.waiting_stage}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e: any) =>
                dispatch({ type: "WAITING", payload: e.target.value })
              }
              id="defaultitem"
              label="Waiting Stage "
              variant="standard"
            />
          </FormControl>
          <Box>
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              size="small"
              onClick={setConfig}
            >
              Set Config
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default ConfigPage;
