import React, { useState } from 'react';
import Button from "@mui/material/Button";
import EditNoteIcon from "@mui/icons-material/EditNotifications";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAxios } from '../../../core/useAxios';
import { APIMETHOD } from '../../../core/constant';

export const initialClientAddress = {
  address: '',
  scope: '',
  efte: '',
  contact_person: '',
  telephone_number: '',
  number_of_shifts: ''
}

const mapAddressData = (data: any) => {
  return {
    address: data.address,
    scope: data.scope,
    efte: data.efte,
    contact_person: data.contact_person,
    telephone_number: data.telephone_number,
    number_of_shifts: data.number_of_shifts,
    id: data.id
  }
}

export const addressReducer = (state: any, action: any) => {
  switch(action.type) {
    case 'SET_ADDRESS':
      return {
        ...state,
        [action.field]: action.payload
      }
    case 'UPDATE_ADDRESS':
      return { ...action.payload }
    case 'SET_INITIALFORM':
      return { ...action.payload }
    default:
      return state;
  }
}

function ClientAddress(props: any) {
  const { state, dispatch, clientAdressList, setClientAdressList, clientDetails } = props;
  const { post, put, del } = useAxios({ showLoader: true });
  const [editedRowId, setEditedRowId] = useState<string | null>(null);
  const [newAddress, setNewAddress] = useState(initialClientAddress);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [addressToDeleteId, setAddressToDeleteId] = useState<string | null>(null);

  const handleAddAddress = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    if (editedRowId) {
      dispatch({
        type: 'SET_ADDRESS',
        field: targetName,
        payload: targetValue
      });
    } else {
      setNewAddress((prev) => ({ ...prev, [targetName]: targetValue }));
    }
  };

  const handleAddressSubmit = async () => {
    if (editedRowId) {
      const response: any = await put({
        url: APIMETHOD.address.ADDRESS_API + editedRowId + '/',
        data: { ...state, client: clientDetails?.id }
      });

      if (response) {
        const updatedAddressList = clientAdressList.map((data: any) => {
          if (data.id === editedRowId) {
            return response;
          }
          return data;
        });

        setClientAdressList(updatedAddressList);
        setEditedRowId(null);
        dispatch({
          type: 'SET_INITIALFORM',
          payload: initialClientAddress
        });
      } else {
        console.log('Server Error, Error Received while updating Address');
      }
    } else {
      const response: any = await post({
        url: APIMETHOD.address.ADDRESS_API,
        data: { ...newAddress, client: clientDetails?.id }
      });

      if (response) {
        setClientAdressList([...clientAdressList, response]);
        setNewAddress(initialClientAddress);
        dispatch({
          type: 'SET_INITIALFORM',
          payload: initialClientAddress
        });
      } else {
        console.log('Server Error, Error Received while creating Address');
      }
    }
  };

  const handleEdit = (data: any) => {
    setEditedRowId(data.id);
    dispatch({
      type: 'UPDATE_ADDRESS',
      payload: mapAddressData(data)
    });
  };

  const handleDeleteConfirmationOpen = (id: string) => {
    setAddressToDeleteId(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setAddressToDeleteId(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDelete = async (id: string) => {
    handleDeleteConfirmationClose(); // Close the confirmation dialog

    const response: any = await del({
      url: APIMETHOD.address.ADDRESS_API + id + '/'
    });

    const updatedAddressList = clientAdressList.filter((data: any) => data.id !== id);
    setClientAdressList(updatedAddressList);

  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Scope Of Site</TableCell>
              <TableCell>EFTE</TableCell>
              <TableCell>No of Shifts</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientAdressList.map((data: any) => (
              <TableRow key={data.id}>
                <TableCell>
                  {editedRowId === data.id ? (
                    <input
                      type="text"
                      value={state.address}
                      name="address"
                      onChange={(e) => handleAddAddress(e)}
                    />
                  ) : (
                    data.address
                  )}
                </TableCell>
                <TableCell>
                  {editedRowId === data.id ? (
                    <input
                      type="text"
                      value={state.scope}
                      name="scope"
                      onChange={(e) => handleAddAddress(e)}
                    />
                  ) : (
                    data.scope
                  )}
                </TableCell>
                <TableCell>
                  {editedRowId === data.id ? (
                    <input
                      type="text"
                      value={state.efte}
                      name="efte"
                      onChange={(e) => handleAddAddress(e)}
                    />
                  ) : (
                    data.efte
                  )}
                </TableCell>
                <TableCell>
                  {editedRowId === data.id ? (
                    <input
                      type="text"
                      value={state.number_of_shifts}
                      name="number_of_shifts"
                      onChange={(e) => handleAddAddress(e)}
                    />
                  ) : (
                    data.number_of_shifts
                  )}
                </TableCell>
                <TableCell>
                  {editedRowId === data.id ? (
                    <Box sx={{ display: "flex" }}>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ minWidth: "auto", p: 0.5, mr: 2 }}
                        onClick={handleAddressSubmit}
                      >
                        Update Address
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ minWidth: "auto", p: 0.5, mr: 2 }}
                        onClick={() => handleEdit(data)}
                      >
                        <EditNoteIcon fontSize="small" />
                      </Button>
                      <Button
                        size="small"
                        color="error"
                        variant="contained"
                        sx={{ minWidth: "auto", p: 0.5 }}
                        onClick={() => handleDeleteConfirmationOpen(data.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <input
                  type="text"
                  value={newAddress.address}
                  name="address"
                  onChange={(e) => handleAddAddress(e)}
                />
              </TableCell>
              <TableCell>
                <input
                  type="text"
                  value={newAddress.scope}
                  name="scope"
                  onChange={(e) => handleAddAddress(e)}
                />
              </TableCell>
              <TableCell>
                <input
                  type="text"
                  value={newAddress.efte}
                  name="efte"
                  onChange={(e) => handleAddAddress(e)}
                />
              </TableCell>
              <TableCell>
                <input
                  type="text"
                  value={newAddress.number_of_shifts}
                  name="number_of_shifts"
                  onChange={(e) => handleAddAddress(e)}
                />
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex" }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ minWidth: "auto", p: 0.5, mr: 2 }}
                    onClick={handleAddressSubmit}
                  >
                    Add Address
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            Are you sure you want to delete this address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(addressToDeleteId || '')} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default ClientAddress;
