import { AccountCircle } from "@mui/icons-material";
import { Button, Paper, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Box } from "@mui/system";
import React, { useState } from "react";
import { boardContext } from "../../../App";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./data";
import AddTask from "./addtask";
import Emailtemplate from "./Emailtemplate";
import { APIMETHOD } from "../../../core/constant";
import { useAxios } from "../../../core/useAxios";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { extractEmailFieldsFromString } from "../../../core/emailTemplateStrings";
import { createAuthHeader } from "../../../shared/utils";

import { differenceInWeeks, parseISO } from "date-fns";

interface TaskListProps {
  task: any;
  stageId: any;
  stageNum: number;
  boardIndex: number;
  taskIndex: number;
  isDoneClick: (data: boolean, emailInfo: any, fromAdress: any, clientName: any) => void;
  setTaskDetails: React.Dispatch<React.SetStateAction<any>>;
}

const Tag = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "2px",
  right: "2px",
  backgroundColor: "#ccc",
  color: "#fff",
  borderRadius: "3px",
  padding: "2px 10px",
  fontSize: "0.8rem",
  boxShadow: "0px 1px 2px rgba(0,0,0,0.2)",
}));

const EMAIL_DETAILS: any = {
  from: "",
  to: "",
  cc: "",
  subject: "",
  body: "",
};

function TaskList(props: TaskListProps) {
  const [namedEmail, setNamedEmail] = useState<string>('no');
  const [isEdit, setIsEdit] = useState(false);
  const { stageId, stageNum, boardIndex, task, taskIndex, isDoneClick } = props;
  const { id, client_name, stage } = props.task;
  const { boardData, setBoardData, config } = React.useContext(boardContext);
  const length = boardData?.columns.length || 0;
  const { get, put, error } = useAxios({ showLoader: true });

  const [sendEmail, setSendEmail] = useState(false);
  const [emailDetails, setEmailDetails] = useState<any>(EMAIL_DETAILS);

  const [taskDetails, setTaskDetails] = useState();

  const [{ isDragging }, drag] = useDrag(() => ({
    item: { clients: props.task, prevStageIndex: boardIndex, _taskIndex: taskIndex },
    type: ItemTypes.TASK,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const moveNextStage = async (stageId: any) => {
    const wid = boardData.waiting_stage || -1;
    const index: number = boardData?.columns?.findIndex(
      (x: any) => x.id === stageId
    );
    const nextSTageId = index + 1;
    // For Update client stage Start
    props.setTaskDetails(props.task);
    const updatedClientSTage: any = props.task;
    updatedClientSTage.stage = boardData.columns[nextSTageId].id;
    const response = await EditClientsAPICall(updatedClientSTage);
    // Update clientStage End
    if (response) {
      const taskid = boardData.columns[index].cards.findIndex(
        (x: any) => x.id === id
      );
      boardData.columns[index].cards.splice(taskid, 1);
      let data = { ...props.task, stage: boardData.columns[nextSTageId].name };
      if (boardIndex === length - 2) {
        const waiting_index: number = boardData?.columns?.findIndex(
          (x: any) => x.id === wid
        );
        updatedClientSTage.stage = boardData.columns[waiting_index].id; // Update client stage for waiting column
        await EditClientsAPICall(updatedClientSTage);
        data.stage = boardData.columns[waiting_index].name;
        boardData.columns[waiting_index].cards.push(data);
        isDoneClick(
          true,
          boardData.columns[waiting_index],
          boardData?.from_to_email,
          client_name
        );
      } else {
        boardData.columns[nextSTageId].cards.push(data);
        isDoneClick(
          true,
          boardData.columns[nextSTageId],
          boardData?.from_to_email,
          client_name
        );
      }
      const updatedData = { ...boardData };

      setBoardData(updatedData);
      config.setData(updatedData);

      if (updatedClientSTage.stage === 7 || updatedClientSTage.stage === 13){
        const resp = await get({
          url: APIMETHOD.UPDATE_SALES_REPORT_DROP, 
          params: {
            main_address_country: updatedClientSTage.main_address_country, 
            stage: updatedClientSTage.stage,
            proposal_amount: updatedClientSTage.proposal_amount
          }
        });
      }
    }
  };

  const EditClientsAPICall = async (data: any) => {
    const response: any = await put({
      url: APIMETHOD.clients.EDIT_CLIENT + data.id + "/",
      data: data,
    });
    if (response) {
      return response;
    }
    return "";
  };

  const handleClick = (e: any, clients: any) => {
    if (e.detail === 2) {
      setIsEdit(true);
    }
  };

  const handleMailButtonClick = async () => {
    const emailFields = 
    {
      to: task.contact_email || "",
      cc: "",
      subject: "",
      body: "",
    };
    
    const stageResponse = await fetch(APIMETHOD.stages.GET_STAGE_BY_ID.replace(":id", stage), {
      headers: createAuthHeader(),
    });
    const stageData = await stageResponse.json();
    const emailFieldsObj = extractEmailFieldsFromString(
      stageData?.email_fields || ""
    );

    const to_email = Array.isArray(emailFieldsObj.to)
    ? emailFieldsObj.to[0]
    : emailFieldsObj.to.replace(/"/g, "");

    // Map sender names to email addresses
    const senderNamesInfo: Record<string, string> = {
      "sales@bluewolfcerts.com": "Trenton Steadman,",
      "admin@bluewolfcerts.com": "Cassandra Lynn,",
      "accounts@bluewolfcerts.com": "Zafar Ayub,",
      "audits@bluewolfcerts.com": "Damon Anderson,",
      "info@bluewolfcerts.com": "Seth Shea,",
    };

    // Map sender titles to email addresses
    const senderTitlesInfo: Record<string, string> = {
      "sales@bluewolfcerts.com": "Sales Manager,",
      "admin@bluewolfcerts.com": "Admin,",
      "accounts@bluewolfcerts.com": "Accounts Manager,",
      "audits@bluewolfcerts.com": "Audit Manager,",
      "info@bluewolfcerts.com": "Lead Auditor,",
    };

    const senderName = senderNamesInfo[to_email] || "";
    const senderTitle = senderTitlesInfo[to_email] || "";

    let emailBody = `
      ${senderName}
      ${senderTitle}
      ${to_email}
    `;

    if (to_email == "sales@bluewolfcerts.com"){
      emailBody = `
        Trenton Steadman
        Sales Manager
        sales@bluewolfcerts.com
        US: +1 509 964 6859
        Australia: 1800 681 209
        www.bluewolfcerts.com
      `;
    }

    if (stageData.color_hex && stageData.color_hex === "Black") {
      if (to_email === "sales@bluewolfcerts.com"){
        setNamedEmail('yes');
      }
    } else if(stageData.color_hex && stageData.color_hex === "Pink") {
      if (to_email === "info@bluewolfcerts.com"){
        setNamedEmail('yes');
      }
    } else {
      setNamedEmail('no');
    }
    setEmailInfo({ ...emailFields, body: emailBody, }, to_email);
  };

  const setEmailInfo = (data: any, fromAdress: any) => {
    const emaildata: any = {
      from: fromAdress || "",
      to: data.to || "",
      cc: data.cc || "",
      subject: data.subject || "",
      body: data.body || "",
    };

    setEmailDetails(emaildata);
    setSendEmail(true);
  };

  const getLikelihoodColor = (likelihoodIndicator: number) => {
    switch (likelihoodIndicator) {
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'blue';
      case 4:
        return 'black';
      case 5:
        return 'transparent';
      default:
        return undefined;
    }
  };
  const likelihoodColor = getLikelihoodColor(task.likelihood_indicator);

  const getClientStatusColor = (clientStatus: number) => {
    switch (clientStatus) {
      case 1:
        return '#90EE90';
      case 2:
        return '#FFD580';
      case 3:
        return '#FFB6B6';
      default:
        return undefined;
    }
  };
  const clientStatusColor = getClientStatusColor(task.client_status);

  const getTimeframeColor = (timeframeDateStr: string | null) => {
    if (!timeframeDateStr) {
      return undefined;
    }

    const timeframeDate = new Date(timeframeDateStr);
    if (isNaN(timeframeDate.getTime())) {
      return undefined;
    }

    const currentDate = new Date();
    const diffDays = Math.floor((timeframeDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

    switch (true) {
      case diffDays <= 30:
        return 'red';
      case diffDays <= 60:
        return 'orange';
      case diffDays <= 90:
        return 'yellow';
      case diffDays <= 120:
        return 'green';
      case diffDays <= 150:
        return 'blue';
      case diffDays <= 180:
        return 'purple';
      default:
        return undefined;
    }
  };
  const timeframeColor = getTimeframeColor(task.timeframe_indicator);

  const getAuditStartDateColor = (jobWonDateStr: string, auditStartDateStr: string | null) => {
    if (jobWonDateStr && !auditStartDateStr) {
      const jobWonDate = parseISO(jobWonDateStr);
      const currentDate = new Date();
      const weeksDifference = differenceInWeeks(currentDate, jobWonDate);
      if (task.stage == 4){
        console.log(weeksDifference)
      }
      if (weeksDifference < 1) {
        return 'lightgreen';
      } else if (weeksDifference < 2) {
        return "orange";
      } else {
        return 'red';
      }
    } else {
      return 'transparent';
    }
  };
  const auditStartDateColor = getAuditStartDateColor(task.job_won_date, task.audit_start_date);

  return (
    <>
      {isEdit ? (
        <AddTask
          isOpen={isEdit}
          handleClose={setIsEdit}
          editDetails={{ ...task, boardIndex: stageNum }}
          taskIndex={taskIndex}
        />
      ) : null}

      {sendEmail && (
        <Emailtemplate
          isOpenEmail={sendEmail}
          handleCloseEmail={setSendEmail}
          emailDetails={emailDetails}
          source="tasklist"
          namedEmail={namedEmail}
        />
      )}

      <Box
        ref={drag}
        sx={{
          opacity: 1,
        }}
      >
        <Paper
          elevation={2}
          square
          sx={{
            p: 1,
            mb: 0.5,
            fontSize: 12,
            border: isDragging ? "5px solid #ccc" : "none",
            position: "relative",
            backgroundColor: stage === 42 ? clientStatusColor : task.audit_start_date ? (stage === 4 || stage === 25) ? "lightpink" : "transparent" : (stage === 25 ? (auditStartDateColor ? auditStartDateColor : "transparent") : "transparent"),
          }}
          onClick={(e: any) => handleClick(e, task)}
        >
          {task.audit_anniversary && (
            <Tag
              sx={{
                padding: "1px 5px",
                fontSize: "0.7rem",
              }}
            >
              {task.audit_anniversary}
            </Tag>
          )}
          {task.certification_body && (
            <Tag
              sx={{
                padding: "1px 5px",
                fontSize: "0.8rem",
                marginTop: task.audit_anniversary ? "25px" : "0px",
                border: "1px solid red",
                backgroundColor: "transparent",
                color: "black"
              }}
            >
              {task.certification_body}
            </Tag>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: task.audit_anniversary ? "12px" : "0px",
              marginBottom: 0,
            }}
          >
            {/* <AccountCircle color="secondary" fontSize='small'/>  */}
            <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
              {stage === 7 && likelihoodColor && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <div>Likelihood</div>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: likelihoodColor,
                      marginLeft: '5px',
                    }}
                  />
                </div>
              )}
              {stage === 7 && timeframeColor && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <div>Timeframe</div>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: timeframeColor,
                      marginLeft: '5px',
                    }}
                  />
                </div>
              )}
              <div style={{ marginBottom: '5px' }}>{client_name}</div>
            </div>

            <div style={{ fontSize: '0.7rem' }}>Auditor: {task.assigned_auditor}</div>
            <div style={{ fontSize: '0.7rem' }}>Salesperson: {task.salesperson &&
              <Button
                size="small"
                variant="contained"
                color="success"
                sx={{ fontSize: 8, minWidth: "35px", padding: "2px 5px" }}
                style={{ backgroundColor: task.salesperson.toLowerCase() === "seth shea" ? "red" : "brown", color: "white", marginLeft: "5px" }}
                disabled={true}
              >
                {task.salesperson}
              </Button>
              }
            </div>
          </Box>
          {task.standards && (
            <Box>
              <Typography variant="body2" style={{ fontSize: "0.6rem" }}>
                {task.standards}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flow-root" }}>
            <span style={{fontSize: 11, display:'inline-flex', paddingTop: 0}}>{task.proposal_amount}</span>
            <Box sx={{ float: "right" }}>
              {task.has_actions_pending &&
                <Button
                  size="small"
                  variant="text"
                  color="error"
                  sx={{ minWidth: "auto", p: 0.5, mr: 1 }}
                >
                  <AccessTimeFilledIcon fontSize="small" />
                </Button>
              }
              <Button
                size="small"
                variant="contained"
                color="success"
                sx={{ fontSize: 8, minWidth: "35px", padding: "2px 5px" }}
                onClick={() => moveNextStage(stageId)}
                disabled={stageNum === boardData.columns.length - 1}
              >
                Done
              </Button>
              {task.notes &&
                <Button
                size="small"
                variant="contained"
                color="success"
                sx={{ fontSize: 8, minWidth: "35px", padding: "2px 5px" }}
                style={{ backgroundColor: "black", color: "white", marginLeft: "5px" }}
                disabled={true}
              >
                Notes
              </Button>
              }

              <Button
                size="small"
                variant="contained"
                color="success"
                sx={{ fontSize: 8, minWidth: "35px", padding: "2px 5px" }}
                style={{ backgroundColor: "blue", color: "white", marginLeft: "5px" }}
                onClick={handleMailButtonClick}
              >
                Mail
              </Button>

            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default TaskList;
