import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import { AddCircleOutline, Email } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TaskBoard from "./taskboard";
import AddTask from "./addtask";
import { boardContext } from "../../../App";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Emailtemplate from "./Emailtemplate";

import { useAxios } from "../../../core/useAxios";
import { APIMETHOD } from "../../../core/constant";

import { InputLabel, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from '@mui/material';

// import SendMailDialog from "./SendMailDialog";

function KanbanBoard() {
  // const board = Cardboard;
  const { boardData } = React.useContext(boardContext);
  const [isOpen, setIsOpen] = useState(false);
  // const [isSendMailDialogOpen, setIsSendMailDialogOpen] = useState(false);
  const [systemWidth, setSystemWidth] = useState(1200);
  const deviceWidth = useRef<any>(null);
  const { get, put, error } = useAxios({ showLoader: true });

  const [selectedColor, setSelectedColor] = useState("");
  const [totalDollarValue, setTotalDollarValue] = useState(0);

  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {
    if (deviceWidth.current) {
      let width = deviceWidth.current.offsetWidth;
      setSystemWidth(width);
    }
  }, []);

  useEffect(() => {
    calculateTotalDollarValue();
  }, [selectedColor, boardData]);

  // const handleSendMailDialogClose = () => {
  //   setIsSendMailDialogOpen(false);
  // };

  const downloadSalesReport = async () => {
    try {
      const response = await get({
        url: APIMETHOD.DOWNLOAD_SALES_REPORT, responseType: 'blob'
      });
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Weekly Blue Wolf Sales Report.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        }
        } catch (error) {
          console.error("Error making backend API request:", error);
        }
  };

  // const calculateTotalDollarValue = () => {
  //   if (boardData && boardData.columns) {
  //     const colorClients = boardData.columns
  //       .reduce((accumulator: any[], column: any) => accumulator.concat(column.cards), [])
  //       .filter((client: any) => isSameColor(client.timeframe_indicator, selectedColor) && client.stage === 7);

  //     const totalValue = colorClients.reduce((sum: number, client: any) => {
  //       const proposalAmount = parseFloat(client.proposal_amount) || 0;
  //       return sum + proposalAmount;
  //     }, 0);

  //     setTotalDollarValue(totalValue.toFixed(2));
  //   }
  // };

  const calculateTotalDollarValue = () => {
    if (boardData && boardData.columns) {
      const colorClients = boardData.columns.reduce((accumulator: any[], column: any) => {
        return accumulator.concat(column.cards);
      }, [])
      .filter((client: any) => (client.likelihood_indicator === getColorIndicator(selectedColor)) && client.stage === 7);

      const totalValue = colorClients.reduce((sum: number, client: any) => {
        const proposalAmount = parseFloat(client.proposal_amount) || 0;
        return sum + proposalAmount;
      }, 0);

      setTotalDollarValue(totalValue.toFixed(2));

    }
  };

  const getColorIndicator = (color: string): number => {
    const colorMap: { [key: string]: number } = {
      red: 1,
      orange: 2,
      blue: 3,
      black: 4,
      transparent: 5,
    };
    return colorMap[color] || 0;
  };

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setSelectedColor(event.target.value as string);
  };

  return (
    <>
      <Box ref={deviceWidth}>
        <Box sx={{ position: "absolute", top: "10px", right: "0" }}>
          <InputLabel id="color-label" sx={{ mt: -2, fontSize: '0.8rem' }}>Likelihood</InputLabel>
          <Select
            labelId="color-label"
            value={selectedColor}
            onChange={handleColorChange}
              sx={{ mr: 3, mt: -4, fontSize: '0.8rem', height: '28px' }}
          >
            <MenuItem value="red">Red: hot opportunity</MenuItem>
            <MenuItem value="orange">Amber: warm opportunity</MenuItem>
            <MenuItem value="blue">Blue: cold opportunity</MenuItem>
            <MenuItem value="black">Black: unknown opportunity</MenuItem>
            <MenuItem value="transparent">No color: Haven't tried reaching out</MenuItem>
          </Select>
          <TextField
            id="input-with-sx"
            label={`Forecasted Sales (${selectedColor})`}
            variant="standard"
            value={totalDollarValue}
            InputProps={{
              readOnly: true,
            }}
            sx={{ fontSize: '0.8rem', height: '25px', mr: 1, mt: -2 }}
          />
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            size="small"
            startIcon={<AddCircleOutline />}
            color="primary"
            onClick={() => setIsOpen(true)}
          >
            Add Client
          </Button>
          {/*<Button
            sx={{ ml: 1 }}
            variant="outlined"
            size="small"
            startIcon={<Email />}
            color="primary"
            onClick={() => setIsSendMailDialogOpen(true)}
          >
            Send Mail
          </Button>*/}
          {/* <Box sx={{ display: "inline-block", verticalAlign: "bottom" }}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <SearchIcon sx={{ color: "action.active", mr: 1 }} />
              <TextField id="input-with-sx" label="Search" variant="standard" />
            </Box>
          </Box> */}
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            size="small"
            startIcon={<DownloadIcon />}
            color="primary"
            onClick={downloadSalesReport} 
          >
            Download Sales Report
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {},
        }}
      >
        {isOpen ? (
          <AddTask isOpen={isOpen} handleClose={setIsOpen} taskIndex={-1} />
        ) : null}
        <DndProvider backend={HTML5Backend}>
          {boardData?.columns?.map((list: any, index: number) => {
            return (
              <div key={list.id + "1"}>
                <TaskBoard
                  boardIndex={index}
                  cardDetails={list}
                  key={list.id}
                  stageNum={index}
                  systemWidth={systemWidth}
                />
              </div>
            );
          })}
        </DndProvider>
      </Box>
      {/*<SendMailDialog isOpen={isSendMailDialogOpen} onClose={handleSendMailDialogClose} />*/}
    </>
  );
}

export default KanbanBoard;
