import React, { useReducer, useEffect } from 'react';
import { ReminderAction, ReminderReducer, initialReminderState } from './modal';
import { Autocomplete, Box, Button, FormControl, TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface actionProps {
    addEdit: number,
    reminderData:any,
    userList: any[],
    clientList: any[],
    handleClose: any,
    addActions: any
}

function AddAction(props: actionProps) {
    const { addEdit, reminderData, userList, clientList, handleClose, addActions } = props;
    const [assignedUser, setAssignedUser] = React.useState<any>(null);
    const [assignedClient, setAssignedClient] = React.useState<any>(null);
    const [remindersState, dispatchReminders] = useReducer(ReminderReducer, reminderData);

    useEffect(() => {
        // update add/edit state
        dispatchReminders({
            type: ReminderAction.UPDATE_STATE,            
            payload: reminderData
        });

        // set the assigned user details
        const assignedUserObj = userList.filter((x: any) => x.id === reminderData.assigned_to);
        if (assignedUserObj && assignedUserObj.length > 0) {
            setAssignedUser(assignedUserObj[0]);
        } else {
            setAssignedUser(null);
        }

        // set the assigned client details
        const assignedClientObj = clientList.filter((x: any) => x.id === reminderData.client);
        if (assignedClientObj && assignedClientObj.length > 0) {
            setAssignedClient(assignedClientObj[0]);
        } else {
            setAssignedClient(null);
        }

    }, [reminderData?.id]);

    const defaultUserProps = {
        options: userList,
        getOptionLabel: (option: any) => option.name,
    };

    const defaultClientProps = {
        options: clientList,
        getOptionLabel: (option: any) => option.client_name,
    };

    const handleInputChange = (event: any) => {
        dispatchReminders({
            type: ReminderAction.INPUT_FIELD,
            field: event.target.name,
            payload: event.target.value
        });
    }

    return (
        <Box sx={{ boxShadow: 1, p: 1, m: 2 }}>
            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
            <TextField
                margin="dense"
                size="small"
                label={"Action / Reminder"}
                type="text"
                name='action'
                fullWidth
                variant="standard"
                value={remindersState.action}
                onChange={(e: any) => {
                    handleInputChange(e);
                }}
            />
            </FormControl>
            <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
            <DatePicker
                selected={remindersState.due_date}
                dateFormat="dd/MM/yyyy"
                onChange={(date: Date) => {
                    dispatchReminders({
                        type: ReminderAction.INPUT_FIELD,
                        field: 'due_date',
                        payload: date
                    });
                }}
                customInput={
                <TextField
                    size="small"
                    margin="dense"
                    id="Due Date"
                    name='due_date'
                    label={"Due Date"}
                    type="text"
                    value={remindersState.due_date}
                    fullWidth
                    variant="standard"
                />
                }
            />
            </FormControl>
            <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <Autocomplete
                    {...defaultClientProps}
                    id="client-select"
                    value={assignedClient}
                    onChange={(event: any, newValue: any | null) => {
                        setAssignedClient(newValue);
                        dispatchReminders({
                            type: ReminderAction.INPUT_FIELD,
                            field: 'client',
                            payload: newValue.id
                        });
                    }}
                    renderInput={(params) => (
                        <TextField margin="dense" {...params} label="Select Client" name='client' variant="standard" />
                    )}
                />
            </FormControl>
            <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
                <Autocomplete
                    {...defaultUserProps}
                    id="user-select"
                    value={assignedUser}
                    onChange={(event: any, newValue: any | null) => {
                        setAssignedUser(newValue);
                        dispatchReminders({
                            type: ReminderAction.INPUT_FIELD,
                            field: 'assigned_to',
                            payload: newValue.id
                        });
                    }}
                    renderInput={(params) => (
                        <TextField margin="dense" {...params} label="Assigned To" name='assigned_to' variant="standard" />
                    )}
                />
            </FormControl>
            <FormControl variant="standard" sx={{ p: 1, width: "50%" }}>
            <TextField
                margin="dense"
                label={"Note"}
                type="text"
                name='notes'
                size="small"
                fullWidth
                variant="standard"
                value={remindersState.notes}
                onChange={(e: any) => {
                    handleInputChange(e);
                }}
            />
            </FormControl>
            <Box sx={{ margin: "15px 0" }}>
                <Button
                  onClick={() => {
                        addActions(remindersState);
                        dispatchReminders({
                            type: ReminderAction.SUBMIT_SUCCESS
                        });
                    }}
                  variant="contained"
                  sx={{ backgroundColor: "#000" }}
                >
                  {addEdit === 1 ? 'Add Action' : 'Save Action'}
                </Button>
                <Button
                  onClick={() => {handleClose()}}
                  variant="contained"
                  sx={{ backgroundColor: "#000", marginLeft: '10px'}}
                >
                  {'Close'}
                </Button>
            </Box>
        </Box>
    );
}

export default AddAction;