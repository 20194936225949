import { UserAction, UserActionTypes } from "./userActionTypes";

// Define state interface
export interface UserState {
  loading: boolean;
  users: any[];
  error: string;
}

// Define initial state
export const initialState: UserState = {
  loading: false,
  users: [],
  error: "",
};

// Define reducer function
export const UserReducer = (
  state: UserState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UserActionTypes.FETCH_USERS_REQUEST:
    case UserActionTypes.POST_USER_REQUEST:
    case UserActionTypes.PATCH_USER_REQUEST:
    case UserActionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: "",
      };
    case UserActionTypes.POST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
        error: "",
      };
    case UserActionTypes.PATCH_USER_SUCCESS:
      const list = [...state.users];
      const foundIndex = state.users?.findIndex((x: any) => x.id === action.payload?.id);
      list.splice(foundIndex, 1, action.payload);
      
      return {
        ...state,
        loading: false,
        users: [...list],
        error: "",
      };
    case UserActionTypes.DELETE_USER_SUCCESS:
      const data1 = state?.users?.filter((x: any) => x.id !== action.payload.id) || state;
      return {
        ...state,
        loading: false,
        users: [...data1],
        error: "",
      };
    case UserActionTypes.FETCH_USERS_FAILURE:
    case UserActionTypes.POST_USER_FAILURE:
    case UserActionTypes.PATCH_USER_FAILURE:
    case UserActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
