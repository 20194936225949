import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

interface AlertConfig {
    isOpen: boolean,
    handleClose: (data: any) => void;
    message: string,
    severity: string
}

function AlertMsg(props: any) {
    const { isOpen, handleClose, message, severity } = props;
    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isOpen} autoHideDuration={6000} onClose={() => handleClose(false)}>
            <Alert onClose={() => handleClose(false)} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default AlertMsg;