import {
    Alert,
    Box,
    Button,
    FormControl,
    Link,
    TextField,
  } from "@mui/material";
  import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
  } from "react";
  import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
  // const fs = require('fs');
  import { boardContext } from "../../../App";
  import { AddCircleOutline } from "@mui/icons-material";
  import EditUser from "./edituser";
  import AlertMsg from "../../../shared/components/AlertMsg";
  import ConfirmationDialog from "../../../shared/components/ConfirmationDialog";

  import {
    UserReducer,
    initialState as initialUserState,
  } from "../../../reducers/userReducer";

  import {
    deleteUser,
    fetchUsers,    
    patchUser,
    postUser,
  } from "../../../reducers/userReducer/userActions";
  
  let paramData: any;  

  const defaultUserToAdd = {
    name: '',
    email: ''
  };
  
  function Users() {
    const { config } = useContext(boardContext);
    const [userAction, setUserAction] = useState<number>(0);
    const [isUserDelete, setIsUserDelete] = useState(false);    
    const [isConfirmation, setIsConfirmation] = useState(false);  

    const [userToAdd, setUserToAdd] = useState(defaultUserToAdd);
    const [usersState, dispatchUsers] = useReducer(UserReducer, initialUserState);

    const rows = usersState.users;

    const columns: any = [
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "name",
        headerName: "User Name",
        width: 250,
      },
      {
        field: "email",
        headerName: "Email",
        width: 350,
      },      
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        width: 200,
        renderCell: (params: any) => {
          return (
            <>
              <Button
                variant="contained"
                color="success"
                sx={{ marginRight: "10px" }}
                size="small"
                onClick={() => onEditClick(params.row)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={() => onDeleteClick(params)}
              >
                Delete
              </Button>
            </>
          );
        },
      },
    ];

    useEffect(() => {
      fetchUsers(dispatchUsers, config?.getToken());
    }, []); 

    const handleAddClick = () => {
      setUserToAdd(defaultUserToAdd);
      setUserAction(1);
    };

    const onEditClick = (userData: any) => {    
      setUserToAdd(userData);
      setUserAction(2);
    }
  
    const onDeleteClick = (params: any) => {
      paramData = params;
      setIsConfirmation(true);
    };

    const confirmDelete = () => {
      const params = paramData;
      if (params) {
        deleteUser(
          dispatchUsers,
          {
            ...paramData
          },
          config?.getToken()
        );
        confirmDiloagClose();
      }
    };    
  
    const confirmDiloagClose = () => {
      setIsConfirmation(false);
    };

    const handleClose = () => {
      setUserAction(0);
    }
    
    const handleUserAction = async (userData:any) => {   
      // edit user ddetails
      if (userAction === 2) {
        const updateUser: any = await patchUser(
          dispatchUsers,
          {
            ...userData
          },
          config?.getToken()
        );
        if (updateUser) {
          setUserAction(0);          
        }
      } else if (userAction === 1) {
        postUser(
          dispatchUsers,
          {
            ...userData
          },
          config?.getToken()
        );
        setUserAction(0);
      }
    };    

    return (
      <>
        <ConfirmationDialog
          isOpen={isConfirmation}
          handleClose={confirmDiloagClose}
          handleConfirm={confirmDelete}
        />
        <AlertMsg
          isOpen={isUserDelete}
          handleClose={() => setIsUserDelete(false)}
          message="User Can not be deleted because it has some actions"
          severity="error"
        />
        <Box sx={{ position: "absolute", top: "-10px", right: "0" }}>
          <Button
            sx={{ mb: 1, mt: 1 }}
            variant="outlined"
            size="small"
            startIcon={<AddCircleOutline />}
            color="primary"
            onClick={handleAddClick}
          >
            Add User
          </Button>
        </Box>  
        {userAction !== 0 && (
          <EditUser     
            userData={userToAdd}       
            handleClose={handleClose}
            handleSubmit={handleUserAction}            
          />
        )}       
        <div>
          <Box sx={{ width: "100%", height: "auto", mb: 3 }}>
            <DataGrid
              rows={rows}
              sx={{ width: "100%" }}
              columns={columns}
              getRowId={(row: any) => row.id}
              autoHeight={true}
              disableSelectionOnClick
              density="compact"
            />
          </Box>
        </div>
      </>
    );
  }
  
  export default Users;
  